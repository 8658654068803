import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";

interface NewsletterSubscriptionProps {
  onSubscriptionChange: (subscribed: boolean) => void;
}

const NewsletterSubscription: React.FC<NewsletterSubscriptionProps> = ({
  onSubscriptionChange,
}) => {
  const [subscribed, setSubscribed] = useState<boolean>(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSubscriptionStatus = event.target.checked;
    setSubscribed(newSubscriptionStatus);
    onSubscriptionChange(newSubscriptionStatus);
  };

  return (
    <div className="flex p-2 w-80 items-center break-words font-poppins text-xs lg:flex-row">
      <Checkbox
        data-testid="newsletter-checkbox"
        sx={{
          color: "#007bff",
          "&.Mui-checked": {
            color: "#007bff",
          },
        }}
        checked={subscribed}
        onChange={handleCheckboxChange}
      />
      I agree to subscribe to our newsletter to get the latest job details &
      other offers.
    </div>
  );
};

export default NewsletterSubscription;
